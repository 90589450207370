const appearanceLanguage = {


  //side bar main 
  Discover:"Discover",
  Jobs:"Jobs",
  Candidates:"Candidates",
  Reports:"Reports",
  Help:"Help",





  Appearance: "Appearance Settings",
  Appearance_Description: "Customizing your workspace, make it more enjoyable and comfortable to work!",
  Custom_theme: "Create custom theme",
  Inter_face_theme: "Interface theme",
  Inter_face_theme_Description: "Choose your favorite theme from here.",
  System_light_PurpleBlue: "System - Light",
  System_Dark_PurpleBlue: "System - Dark",
  System_light_Pink: "System - Pink",
  Sidebar_feature: "Sidebar feature",
  Sidebar_feature_Description: "What shows in the desktop sidebar.",
  Recent_used_apps: "Recent used apps",
  Language: "Language",
  Language_Description: "Language Settings.",
  Select_Language: "Select Language",
  Select_Language_Description: "Choose your language from the drop-down list",
  English: "English",
  Show_Arabic_in_table_fields: "Show Arabic in table fields",
  Show_Arabic_in_table_fields_Description: "This will show Arabic in table fields when creating and editing items.",
  Enable: "Enable",
  Enable_Description: "Command bar functionality will disable.. lorem ipsum",
  Search_Command_Bar: "Search & Command Bar",
  Search_Command_BarDescription: "Use this drop-down menu to expand",
  Show_Suggestion: "Show Suggestion",
  Show_Suggestion_Description: "This will display suggestions in Arabic.",
  Disable_Voice_Comma: "Disable voice commands",
  Disable_Voice_Comma_Description: "Control voice command here.",



  //side bar 
  Settings:"Settings",
  General:"General",
  Appearance:"Appearance",
  Notification:"Notification",
  Other:"Other",
  Role_and_Privileges:"Role and Privileges",
  Company:"Company",
  Team_members:"Team Members",
  System_settings:"System settings",
  Integrations:"Integrations",
  Templates:"Templates",
  Theme_color:"Theme Color",
  Choose_Color:"Choose Color",

  //comon

  comon_Dscription:"Coordinates the planning, execution, and completion of projects...",
};
export default appearanceLanguage;
