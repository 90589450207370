import joblist from "./Jobs/Arabic";
import appearance from "./Settings/Appearance/Arabic";
import otherLanguageArabic from "./Other/Arabic"


const arabicLanguage = {
  ...joblist,
  ...appearance,
  ...otherLanguageArabic
};

export default arabicLanguage;