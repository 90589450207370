const otherLanguageArabic = {
    
    Email_notification:"إشعار البريد الإلكتروني",
    Email_Notification_description:"وصف",
    Salary_Slip:"وصل الراتب",
    Salary_Certificate:"شهادة الراتب",
    Gratuity_Approval:"الموافقة على المكافأة",
    Loan_approvals:"الموافقات على القروض",
    Subtitle_Notfication:"سيؤدي هذا إلى إظهار اللغة العربية في حقول الجدول عند إنشاء العناصر وتحريرها.",
    notification_dsc:"ينسق التخطيط والتنفيذ والانتهاء من المشاريع...",

    Role_and_Privileges:"الدور والامتيازات",
    Roles_Management:"إدارة الأدوار",
    Attendance_Access:"وصول الحضور",
    Access_Settings:" إعدادات الوصول",
    Role_List:"قائمة الأدوار",
    Users_List:"قائمة المستخدمين",
    Action:"إجراء",
    Status:"حالة",
    Employees:"موظفين",
    Roles:"الأدوار",
    Create_Role:"إنشاء دور",



    Departments:"الأقسام",
    Locations:"المواقع",
    Name:"اسم",
    Description:"وصف",


    Team_Members:"أعضاء الفريق",
    Contact:"اتصال",
    Image:"صورة",
    Add_Team_Member:"إضافة عضو الفريق",


    System_Settings:"اعدادات النظام",
    Listing_all_system:"سرد جميع تكوينات النظام",
    Listing_All_description:"لوريم إيبسوم نص دمية دولار الجلوس.",
    Prefix:"بادئة",
    Job_Code_Format:"تنسيق رمز الوظيفة",
    Sort_by:"ترتيب حسب",
    Filters:"المرشحات",

    Main_Description:"ينسق تخطيط وتنفيذ وإكمال المشاريع",
    System_Settings:"اعدادات النظام",
    Integration:"التكامل",



    Templates:"قوالب",
    Letter:"خطاب",
    Questionnaire:"استبيان",
       Evaluation:"تقييم",
            Email:"بريد إلكتروني",
         Workflow:"سير العمل",
  Job_Description:"المسمى الوظيفي",
    Job_Templates:"قوالب الوظائف",
    Name:"اسم",
    Description:"وصف",
         Status:"حالة",
};
export default otherLanguageArabic;