const appearanceLanguageArabic = {
  Appearance: "مظهرإعدادات المظهر",
  Jobs: "وظائف",
  Job: "وظيفة",
  My_Open_Jobs: "وظائفيالمفتوحة",
  All_Jobs: "جميعالوظائف",
  Applied: "تم_التقديم",
  Open: "مفتوحة",
  Draft: "مسودة",
  Type: "نوع",
  Location: "الموقع",
  Posted_By: "نشر بواسطة",
  Date: "تاريخ",


  Total_No_of_Jobs_Posted: "إجمالي عدد الوظائف المنشورة",
  Source_Diversity: "تنوع المصادر",
  Open_Jobs: "الوظائف المفتوحة",
  Rejection_Average: "متوسط الرفض",
  Hired_Count: "عدد الموظفين المُقبَلين",

  Source: "المصدر",
  Stage: "المرحلة",
  Applied_Date: "تاريخ التقديم",


  Add_Candidate: "إضافة المرشحين",
  Personal_Details: "التفاصيل الشخصية",
  Educational_Details: "التفاصيل التعليمية",
  Work_Experience: "الخبرة العملية",
  Review: "مراجعة",


  Head_Of_Director: "رئيس المدير",
  Head_of_director: "رئيس المدير",
  help: "مساعدة",
  Personal_Details: "تفاصيل شخصية",
  Prefix: "البادئة",
  First_Name: "الاسم الأول",
  Last_Name: "الاسم الأخير",
  Phone_number: "رقم الهاتف",
  City_Or_Town: "المدينة أو البلدة",
  Address_Line: "سطر العنوان",
  Postal_Code: "الرمز البريدي",
  Create_a_Job: "إنشاء وظيفة",



  Job_Details: "تفاصيل الوظيفة",
  Application_Form: "نموذج التقديم",
  Workflow: "سير العمل",
  Team_Member: "أعضاء الفريق",
  Publish: "نشر",

};
export default appearanceLanguageArabic;
