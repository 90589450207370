import { Flex } from "antd";
import React from "react";
import { HiMiniStar } from "react-icons/hi2";

export default function Heading({
  className,
  title = "",
  description = "",
  required = false,
  font = "20px",
}) {
  return (
    <div className={`flex flex-col  justify-center gap-0.5 ${className}`}>
      <div className="flex">
        <h1 className={`h2`}>{title}</h1>
        {required && <HiMiniStar className="text-[10px] text-rose-600" />}
      </div>
      <p className="para font-medium ">{description}</p>
    </div>
  );
}
